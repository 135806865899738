<template>
  <div class="container">
    <div class="login-module">


      <!-- 手机号登录 -->
      <!-- <phone-login v-if="login_page_status == 2" /> -->
      <!-- 手机号登录 end -->

      <!-- 注册 -->
      <!-- <register-temp v-else-if="login_page_status == 3" /> -->
      <!-- 注册 end -->

      <!-- 找回密码 -->
      <!-- <retrieve-temp v-else-if="login_page_status == 4"/> -->
      <!-- 找回密码 end -->

      <!-- 扫码登录 -->
      <!-- <qrcode-login v-else /> -->
      <!-- 扫码登录 end -->

      <router-view @on-common-change="onConmonChange"></router-view>



    </div>


  </div>
</template>

<script>
  import {
    mapState,
    mapMutations
  } from 'vuex';
  import qrcodeLogin from './components/qrcode-login';
  import phoneLogin from './components/phone-login';
  import registerTemp from './components/register-temp';
  import retrieveTemp from './components/retrieve-temp';
  import bindingTemp from './components/binding-temp';

  export default {
    // 允许组件模板递归地调用自身
    name: 'Login',
    // 声明一组可用于组件实例中的组件
    components: {
      qrcodeLogin,
      phoneLogin,
      registerTemp,
      retrieveTemp,
      bindingTemp,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {},
    // 该函数返回组件实例的 data 对象
    data() {
      return {}
    },
    // 计算属性：
    computed: {
      ...mapState(['login_page_status']),
    },
    // 钩子函数--侦听data变动：
    watch: {
      // 监听路由变化，并同步到导航组件
      // $route(to,from){
      //   console.log('监听路由变化 to == ',to);
      //   console.log('监听路由变化 from == ',from);
      //   let { id } = to.query;
      //   console.log('id == ',id);
      //   if(id) {
      //     this.loginPageStatusChange(id);
      //   }
      // }
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      ...mapMutations(['loginPageStatusChange', 'isLoginStatusChange']),
      /**
       * 当前页组件公共变换
       */
      onConmonChange(e) {
        console.log('当前页组件公共变换 e == ', e);
        // 登录信息请求
        this.myLoginInfoRequest().then((res) => {
          // vuex 调用函数 改变状态
          console.log('当前页组件公共变换 this.$store == ', this.$store);
          // Cookie 设置缓存-登录状态
          this.mySetCookie(this.STORAGE.login_token, true);
          // this.mySetCookie(this.STORAGE.login_token,true,60*60*24);
          // 储存缓存-登录状态
          this.mySetStorage({
            name: this.STORAGE.login_token,
            value: true,
            // expires: 1000 * 60 * 60 * 24, // 时间：存储时长，毫秒数
          });
          this.isLoginStatusChange(true);
          if (this.shareWin) {
            this.shareWin.sandPostMessage(this.shareWin.childWin)
          }
          // 跳转路由
          this.myRouterPush({
            path: '/'
          });
        });
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    // flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    background: #f5f5f5;
    overflow: hidden;
  }

  .login-module {
    // box-sizing: border-box;
    // width: 400px;
    // height: 500px;
    // margin-top: -5%;
    padding: 30px;
    background: #fff;
    margin: 50px 0;
  }

</style>
