<template>
  <div class="qrcode-login">
    <div class="logo">
      <el-image
        :src="ossUrl + '/images/logo-img/login-logo.png'"
        fit="scale-down"
      ></el-image>
    </div>
    <!-- <h3 class="title">微信扫一扫二维码登录“佰团乐”小程序</h3> -->
    <div class="qr-code" id="login_container" v-if="!is_wx_browser"></div>
    <div class="confirm" v-else>
      <el-button class="fake-btn color2" @click="h5LoginFun">
        <i class="iconfont icon-weixin"></i>
        <span>微信一键登录</span>
      </el-button>
    </div>
    <template v-if="!(changeType == 2)">
      <div class="cot">
        <span>或</span>
      </div>
      <div class="confirm">
        <router-link :to="{path: '/login/2'}">
          <el-button class="fake-btn">
            <i class="iconfont icon-shouji"></i>
            <span>手机号登录</span>
          </el-button>
        </router-link>
      </div>
      <div class="agreement">
        <agreement-temp :init-checked="login_checked" title="登录后代表您阅读并同意佰团乐" :is-change="false" @agree-change="function(e) {login_checked = e.checked}" />
        <!-- <el-checkbox class="fake-checkbox" v-model="login_checked">
          <span class="fake-span">登录后代表您阅读并同意佰团乐<span class="diff-color">《用户协议》</span>和<span class="diff-color">《隐私协议》</span></span>
        </el-checkbox> -->
      </div>
    </template>
  </div>
</template>

<script>
// import VConsole from 'vconsole';

// import WxLogin from 'weixin-js-sdk';
import myWxlogin from '@/utils/wxLogin.js';
import {isWeiXinBrowser} from '@/utils/utils_fun.js';


import agreementTemp from '@/views/login/components/agreement-temp.vue';
export default {
  // 允许组件模板递归地调用自身
  name: 'qrcode-login',
  // 声明一组可用于组件实例中的组件
  components: {
    agreementTemp,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    // 改变类型 1/正常 2/第三方弹窗
    changeType: {
      type: [Number, String],
      default: 1,
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      baseUrl: this.GLOBAL.baseUrl,
      ossUrl: this.GLOBAL.ossUrl,
      facility: {}, // 当前设备
      h5LoginFun_info: '测试',
      is_wx_browser: false, // 是否是微信浏览器
      login_checked: true, // 是否同意登录
      login_insurance:0,
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // const vConsole = new VConsole();

      // 获取设备信息
      this.getFacilityInfo();
    },
    getUrlParam(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    },
    /**
     * 获取设备信息
     */
    getFacilityInfo() {
      let facility = this.myGetCurrentFacility();
      console.log('获取设备信息 facility == ',facility)
      let login_page = this.getUrlParam("page"); //参数名
      if(login_page){
        this.login_insurance = 1;
      }
      console.log('lfp---------- ',this.login_insurance);
      this.facility = facility;
      this.is_wx_browser = isWeiXinBrowser();
      console.log('是否是微信浏览器 == ',isWeiXinBrowser())
    },
    /**
     * 设置微信小程序配置
     */
    setWechatConfigFun(id) {

      // wx.config({
      //   beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      //   debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
      //   appId: 'ww76673d8a0d7cd9d7', // 必填，企业微信的corpID
      //   timestamp: 1414587466, // 必填，生成签名的时间戳
      //   nonceStr: 'Wm3WZYTPz0wzccnC', // 必填，生成签名的随机串 必填，生成签名的随机串
      //   signature: that.ticketString, // 必填，签名
      //   jsApiList: ['getBrandWCPayRequest']
      // });
      // var obj = new WxLogin({
      myWxlogin({
        needResult: 1,
        // self_redirect:true, // true：手机点击确认登录后可以在 iframe 内跳转到 redirect_uri，false：手机点击确认登录后可以在 top window 跳转到 redirect_uri。默认为 false。
        id: "login_container", // 第三方页面显示二维码的容器id
        appid: this.GLOBAL.applet_appid, // 应用唯一标识，在微信开放平台提交应用审核通过后获得
        scope: 'snsapi_login', // 应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
        redirect_uri: encodeURIComponent(this.baseUrl+"/api/login/wechatscanlogin&application_client_type=pc&login_insurance="+this.login_insurance), // 重定向地址，需要进行UrlEncode
        state: id, // 用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加session进行校验
        style: "black", // 提供"black"、"white"可选，默认为黑色文字描述。
        href: "/", // 自定义样式链接，第三方可根据实际需求覆盖默认样式。
      });
    },
    /**
     * 获取微信小程序配置
     * 返回参数：
     * 参数	是否必须	说明
     * appid	是	应用唯一标识，在微信开放平台提交应用审核通过后获得
     * secret	是	应用密钥AppSecret，在微信开放平台提交应用审核通过后获得
     * code	是	填写第一步获取的code参数
     * grant_type	是	填authorization_code
     */
    getCode () {
      this.myRequest({
        url: '/api/login/scaninit'
      }).then(res => {
        console.log('微信小程序配置 res == ',res);
        let loginId = res.data.data.id;
        this.setWechatConfigFun(loginId);
      }).catch(err => {
        console.log('微信小程序配置 err == ',err);
      })
    },
    /**
     * H5登录
     */
    h5LoginFun() {
      // 获取/设置-邀请用户缓存
      let referrer = this.mySetAndGetInviteUserId(false);
      console.log('获取/设置-邀请用户缓存 referrer == ',referrer)
      this.myRequest({
        url: '/api/login/authorize',
        data: {
          referrer: referrer,
        }
      }).then(res => {
        console.log('H5登录 res == ',res);
        // console.log('H5登录 window == ',window);
        let resData = res.data.data;
        if(this.changeType == 2) {
          // 告知变化
          this.informChangeFun({
            visible: false,
            status: 1, // 1/成功 2/取消 3/其他
            message: '成功',
            data: '',
            event: 'on-change',
          });
        } else {
          // 告知变化
          this.informChangeFun({
            visible: false,
            status: 1, // 1/成功 2/取消 3/其他
            message: '成功',
            data: '',
            event: '',
          });
        }
        // 跳转链接
        window.location.href = resData.url;
      })
    },
    /**
     * 告知变化
     */
    informChangeFun(opt) {
      let default_data = {
        visible: false,
        status: 2, // 1/成功 2/取消 3/其他
        message: '取消',
        data: '',
        event: 'on-common-change',
      };
      let result = Object.assign(default_data,opt);
      this.$emit(event,result)
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {
    // 微信小程序配置
    this.getCode();
  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.qrcode-login {
    $rowGutter: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .logo {
      position: relative;
      left: 3px;
      width: 50px;
      height: 50px;
      margin: 0 auto $rowGutter;
      // background: $theme-photo-background-color;
      .el-image {
        width: auto;
        height: 100%;
      }
    }
    .title {
      text-align: center;
      font-size: 16px;
      color: $theme-lv1-color;
      display: none;
    }
    .qr-code {
      // width: 220px;
      // height: 220px;
      margin: 0 auto;
      // background: $theme-photo-background-color;
    }
    .cot {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: $theme-lv2-color;
      margin: $rowGutter 0;
      &::before, &::after {
        content: '';
        display: inline-block;
        width: 30%;
        height: 1px;
        background: $theme-photo-background-color;
      }
      & > span {
        display: inline-block;
        box-sizing: border-box;
        padding: 0 20px;
      }
    }
    .confirm {
      width: 70%;
      margin: $rowGutter auto;
      .fake-btn {
        width: 100%;
        font-size: 14px;
        color: #fff;
        border: 1px solid $common-number1-color;
        background: $common-number1-color;
        outline: none;
        &.el-button:hover,&.el-button:focus,&.el-button:active {
          color: none;
          border-color: none;
          background-color: none;
        }
        .iconfont {
          margin-right: 6px;
        }
        &.color2 {
          border: 1px solid $common-number2-color;
          background: $common-number2-color;
        }
      }
    }
    .agreement {
      $checked-color: #36B93F;
      margin-top: 50px;
      .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: $checked-color;
        border-color: $checked-color;
      }
      &::v-deep .fake-checkbox {
        .fake-span {
          font-size: 14px;
          color: #333;
          .diff-color {
            color: $checked-color;
          }
        }
        .el-checkbox__input.is-focus .el-checkbox__inner {
          border-color: $checked-color;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: $checked-color;
          border-color: $checked-color;
        }
      }
    }
  }
</style>
