<template>
  <div class="register-form">
    <h3 class="title">注册用户</h3>
    <div class="form-module">
      <div class="field-item">
        <div class="input-key">手机号：</div>
        <div class="input-box">
          <el-input maxlength="11" v-model="phone" placeholder=""></el-input>
        </div>
      </div>
      <div class="field-item">
        <div class="input-key">密码：</div>
        <div class="input-box">
          <el-input type="password" v-model="password" placeholder=""></el-input>
        </div>
      </div>
      <div class="field-item">
        <div class="input-key">确认密码：</div>
        <div class="input-box">
          <el-input type="password" v-model="confirm_password" placeholder=""></el-input>
        </div>
      </div>
      <div class="field-item">
        <div class="input-key">验证码：</div>
        <div class="input-box">
          <el-input v-model="verify_code" placeholder=""></el-input>
        </div>
        <div class="sand-verify-code">
          <el-button class="countdown-style" round v-if="count_down">{{count_down}}后重新发送</el-button>
          <el-button round v-else @click="getVerifyCodeFun">发送验证码</el-button>
        </div>
      </div>
    </div>
    <div class="agreement">
      <agreement-temp :init-checked="login_checked" title="我同意佰团乐" @agree-change="function(e) {login_checked = e.checked}" />
      <!-- <el-checkbox class="fake-checkbox" v-model="login_checked">
        <span class="fake-span">我同意佰团乐<span class="diff-color">《用户协议》</span>和<span class="diff-color">《隐私协议》</span></span>
      </el-checkbox> -->
    </div>
    <div class="confirm login">
      <el-button class="fake-btn" @click="clickSubmitBtnFun">
        <span>注册</span>
      </el-button>
      <div class="rest-control">
        <span>已有账户!&nbsp;&nbsp;</span>
        <!-- <router-link :to="{path: '/login/2'}"> -->
          <span class="diff-style" @click="$router.go(-1)">去登录</span>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import agreementTemp from '@/views/login/components/agreement-temp.vue';
export default {
  // 允许组件模板递归地调用自身
  name: 'register-temp',
  // 声明一组可用于组件实例中的组件
  components: {
    agreementTemp,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      phone: '', // 电话号码
      password: '', // 密码
      confirm_password: '', // 确认密码
      verify_code: '', // 验证码
      login_checked: true, // 同意登录
      count_down: '', // 是否在倒计时
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 点击提交按钮
     */
    clickSubmitBtnFun() {
      // 获取/设置-邀请用户缓存
      let referrer = this.mySetAndGetInviteUserId(false);
      console.log('获取/设置-邀请用户缓存 referrer == ',referrer)
      // 校验数据
      let flag = this.verifyDataFun();
      if(!flag) return;

      // 发起注册请求
      this.sandRegisterRequestFun()
    },
    /**
     * 发起注册请求
     */
    sandRegisterRequestFun() {
      // 获取/设置-邀请用户缓存
      let referrer = this.mySetAndGetInviteUserId(false);
      console.log('获取/设置-邀请用户缓存 referrer == ',referrer)
      let { phone, password, confirm_password, verify_code } = this;
      this.myRequest({
        method: 'post',
        url: '/api/login/reg',
        data: {
          'accounts': phone,
          'verify': verify_code,
          'pwd': password,
          'type': 'sms',
          'referrer': referrer,
        }
      }).then((res) => {
        console.log('发起注册请求 res == ',res)
        // 告知变化
        this.informChangeFun({status: 1});
        // 跳转路由
        this.myRouterPush({path: '/'});
      }).catch((err) => {
        console.log('发起注册请求 err == ',err)
      })
    },
    /**
     * 告知变化
     */
    informChangeFun(opt) {
      let default_data = {
        visible: false,
        status: 2, // 1/成功 2/取消 3/其他
        message: '取消',
        data: '',
      };
      let result = Object.assign(default_data,opt);
      this.$emit('on-common-change',result)
    },
    /**
     * 获取验证码
     */
    getVerifyCodeFun() {
      // 电话号码
      if(!this.phone || !this.REGEXP.is_phone.test(this.phone)) {
        this.myMessage({
          message: '请输入有效的电话号码！'
        });
        return false;
      }
      this.myRequest({
        method: 'post',
        url: '/api/login/regverifysend',
        data: {
          'accounts': this.phone,
          'type': 'sms',
        }
      }).then((res) => {
        console.log('获取验证码 res == ',res)
        // 倒计时
        this.countDownFun();
      }).catch((err) => {
        console.log('获取验证码 err == ',err)
      })
    },
    /**
     * 倒计时
     */
    countDownFun() {
      let end_time = 60;
      let timer = setInterval(() => {
        if(end_time <= 0) {
          clearInterval(timer);
          this.count_down = '';
          return;
        }
        end_time--;
        this.count_down = end_time;
      },1000)
    },
    /**
     * 校验数据
     */
    verifyDataFun() {
      let { phone, password, confirm_password, verify_code, login_checked } = this;

      // 电话号码
      if(!phone || !this.REGEXP.is_phone.test(phone)) {
        this.myMessage({
          message: '请输入有效的电话号码！'
        });
        return false;
      }

      // 密码
      if(!password) {
        this.myMessage({
          message: '请输入密码！'
        });
        return false;
      }

      // 确认密码
      if(!confirm_password) {
        this.myMessage({
          message: '请再次输入密码！'
        });
        return false;
      } else if(confirm_password != password) {
        this.myMessage({
          message: '两次输入的密码不一样！'
        });
        return false;
      }

      // 验证码
      if(!verify_code) {
        this.myMessage({
          message: '请输入验证码！'
        });
        return false;
      }

      // 登录协议
      if(!login_checked) {
        this.myMessage({
          message: '请阅读并勾选同意遵守用户协议和隐私协议！'
        });
        return false;
      }

      return true;
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
}

.register-form {
  min-width: 432px;
  $checked-color: #36B93F;
  $rowGutter: 10px;
  .title {
    text-align: center;
    font-size: 16px;
    color: $theme-lv1-color;
  }
  .form-module  {
    width: 90%;
    margin: $rowGutter auto;
    .field-item {
      $height: 35px;
      display: flex;
      margin: 30px 0;
      font-size: 14px;
      .input-key {
        width: 76px;
        height: $height;
        line-height: $height;
        text-align: left;
        font-size: 14px;
        color: #444343;
      }
      .input-box {
        flex: 1;
        min-width: 0;
        display: flex;
        height: $height;
        border: 1px solid #787878;
        border-radius: 4px;
        &::v-deep .el-input {
          height: $height;
          .el-input__inner {
            border: none;
            height: $height;
            line-height: $height;
            padding: 0 10px;
          }
        }
      }
      .sand-verify-code {
        margin-left: 40px;
        &::v-deep {
          .el-button {
            color: #fff;
            height: $height;
            line-height: 0.8;
            border-color: $common-number1-color;
            background-color: $common-number1-color;
            &.countdown-style {
              border-color: #787878;
              background-color: #787878;
            }
          }
        }
      }
    }
  }
  .agreement {
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    &::v-deep .fake-checkbox {
      .fake-span {
        font-size: 14px;
        color: #333;
        .diff-color {
          color: $checked-color;
        }
      }
      .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color:  $checked-color;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color:  $checked-color;
        border-color:  $checked-color;
      }
    }
  }
  .confirm {
    width: 90%;
    margin: $rowGutter auto;
    .fake-btn {
      width: 100%;
      font-size: 14px;
      color: #fff;
      border: 1px solid $common-number2-color;
      background: $common-number2-color;
      outline: none;
      &.el-button:hover,&.el-button:focus,&.el-button:active {
        color: none;
        border-color: none;
        background-color: none;
      }
      .iconfont {
        margin-right: 6px;
      }
    }
    .rest-control {
      margin-top: $rowGutter;
      text-align: center;
      font-size: 12px;
      color: #787878;
      .diff-style {
        color: $common-number1-color;
        cursor: pointer;
      }
    }
    &.login {
      .fake-btn {
        border: 1px solid $common-number1-color;
        background: $common-number1-color;
      }
    }
  }
}
</style>
